<template>
  <div class="vg_wrapper">
    <el-card>
      <div style="display: flex">
        <div>
          <div class="vd_button_group vg_mtb_16">
            <!--            <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>-->
            <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
          </div>
          <div class="vd_button_group vg_mtb_16 flexV">
            <el-button-group>
              <el-button size="small" @click="getDataByType(1)">外销</el-button>
              <el-button size="small" @click="getDataByType(2)">内贸</el-button>
            </el-button-group>
          </div>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`sconorder_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getSconsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { sconAPI } from '@api/modules/sconOrder';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import { useCustMixin, useStffMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SalesManagement/SconChangeManage/scon';
import { get } from 'axios';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SconList',
  mixins: [useStffMixin, useCustMixin],
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: tableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      accumulatedAmount: {}
    };
  },
  created() {
    //this.getCustDport();
    //this.getCustPayway();
  },
  mounted() {
    let { scon_no, prod_no } = this.$route.query;
    if (scon_no) {
      this.$refs.multiTable.searchForm.scon_no = scon_no;
      this.$refs.multiTable.searchForm.scon_shipstat = null;
    }
    if (prod_no) {
      this.$refs.multiTable.searchForm = { ...this.$route.query, ...this.$refs.multiTable.searchForm };
    }
    this.$refs.multiTable.searchForm.scon_type = 1;
    this.getScons();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/scon_add' || from.path === '/scon_edit') {
        this.getScons();
      }
    }
  },
  methods: {
    getScons() {
      let searchForm = this.$refs.multiTable.searchForm;
      getNoCatch(sconAPI.getScons, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        for (const key in data) {
          if (key.includes('sum')) {
            this.accumulatedAmount[key] = data[key];
          }
          // this.tableProperties[this.tableProperties.findIndex(({ prop }) => prop === key.substring(4))][key] = data[key];
        }
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    /**
     * 获取港口
     */
    getCustDport() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10018 }).then(({ data }) => {
        this.tableProperties[12].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
    },
    /**
     * 获取付款方式
     */
    getCustPayway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10013 }).then(({ data }) => {
        this.tableProperties[11].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
    },
    /**
     * 查询方法
     */
    getSconsNow() {
      this.loadFlag = true;
      this.getScons();
    },
    /**
     * 刷新
     */
    buttonRefresh() {
      this.loadFlag = true;
      this.$refs.multiTable.resetFields();
      this.$refs.multiTable.searchForm.scon_type = 1;
      this.getScons();
    },
    /**
     * 分页查询
     */
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getScons();
    },
    /**
     * 多选获取公司抬头信息
     */
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    /**
     * 新增
     */
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/scon_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.sconorder_id);
      });
      getNoCatch(sconAPI.deleteSconByIds, { delete_scon_by_ids: ids.toString() })
        .then(res => {
          this.$message.success('删除成功!');
          if (ids.length === this.tableData.length) {
            if (this.currentPage > 1) {
              this.currentPage = this.currentPage - 1;
              this.$refs.pubPagination.currentPage = this.currentPage;
            }
          }
          this.getScons();
        })
        .catch(err => {
          this.$message.error(err);
          this.getScons();
        });
    },
    /**
     * 双击row跳转到详情编辑
     */
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/sconorder_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.sconorder_id,
            scon_type: row.scon_type
          })
        )
      });
    },
    getDataByType(type) {
      this.$refs.multiTable.searchForm.scon_type = type;
      this.getScons();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tooltip {
  width: 100%;
  padding: 0 2px;
  box-sizing: border-box;
  padding-right: 2px;
}
::v-deep .el-tooltip span {
  margin-left: 2px;
  margin-right: 4px;
}
</style>
