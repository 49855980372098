import { getDateNoTime } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';
/**
 * 出运状态返回值
 */
const formatterOrderType = val => {
  switch (val) {
    case '0':
      return '取消订单';
    case '1':
      return '成品明细变更';
    case '2':
      return '其他';
    case '3':
      return '新增内容';
  }
};
export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  { prop: 'scon_no', label: '销售合同号', itemType: 'input', sortable: false, widthAuto: true },
  {
    prop: 'create_time',
    label: '变更日期',
    itemType: 'date',
    sortable: true,
    formatter: val => getDateNoTime(val, true),
    widthAuto: true
  },
  { prop: 'cust_abbr', label: '客户简称', itemType: 'input', widthAuto: true },
  {
    prop: 'order_type',
    label: '变更类型',
    itemType: 'select',
    options: [
      { value: '0', label: '取消订单' },
      { value: '1', label: '成品明细变更' },
      { value: '2', label: '其他' },
      { value: '3', label: '新增内容' }
    ],
    formatter: val => formatterOrderType(val),
    widthAuto: true
  },
  { prop: 'cust_dept_name', label: '销售所属部门', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { prop: 'cust_stff_name', label: '销售经办人', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'input', input: true, labelWidth: '100px', sortable: true, widthAuto: true },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
