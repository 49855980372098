import topic from '../topic';
import { getNoCatch } from '@api/request';
export const sconAPI = {
  getScons: topic.sconOrderTopic + '/get_scons',
  getScons2: topic.sconOrderTopic + '/get_scons2',
  getSconById: topic.sconOrderTopic + '/get_scon_by_id',
  addScon: topic.sconOrderTopic + '/add_scon',
  editScon: topic.sconOrderTopic + '/edit_scon',
  deleteSconByIds: topic.sconOrderTopic + '/delete_scon_by_ids',
  exportSconPdfById: topic.sconOrderTopic + '/pdf/export_scon_pdf_by_id',
  getSconPartBySuppId: topic.sconOrderTopic + '/get_scon_part_by_supp_id',
  getSconMtrbBySuppId: topic.sconOrderTopic + '/get_scon_mtrb_by_supp_id',
  getSconProdsByIds: topic.sconOrderTopic + '/get_scon_prods_by_ids',
  getSconPartBySconId: topic.sconOrderTopic + '/get_scon_part_by_scon_id',
  getProduct: params => getNoCatch(`${topic.sconOrderTopic}/getProduct`, params),
  getPodrSync: topic.sconOrderTopic + '/getPodrSync'
};
